@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@400;800);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
:root {
  --dark: #000;
  --blue: #becae1;
  --pink: #EDC0D3;
  --gray: #2c2c2c;
  --green: #c7e1c5;
  --accent: #ea638c;
  --butter: #f1e1b0;
  --yellow: #EFE0A3;
  --orange: #fbd5b7;
  --purple: #cac7e0;
  --primary: #EDC0D3;
}

a {
  color: #EDC0D3;
  color: var(--primary)
}

body {
  color: #fff;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #000;
  background-color: var(--dark);
}

.border-primary {
  border: 2px solid #EDC0D3 !important;
  border: 2px solid var(--primary) !important;
}

.spot-col {
  padding-left: 2px;
  padding-right: 2px;
}

.click-trailer {
  max-width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}

.hidden {
  visibility: hidden;
}

.app-link {
  cursor: pointer;
  position: relative;
}

.app-link-title {
  text-align: center;
  position: absolute;
  font-size: 3.5rem;
  width: 100%;
  top: 45%;
  z-index: 10;
}

.app-link-logo  {
  position: absolute;
  left: calc(50% - 150px);
  top: calc(60% - 100px);
  width: 300px;
  margin: auto;
  display: block;
  z-index: 10;
}

.app-link-overlay {
  background: rgba(0, 0, 0, 1);
  opacity: 0.4;
  height: 50vh;
  position: absolute;
  display: block;
  top: 0;
  width: 100%;  
}

.user-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  object-fit: cover;
}

.home-row {
  height: 50vh;
}

.home-img {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-icon {
  max-width: 70px;
}

.transfer-deposit {
  max-height: 400px;
  object-fit: contain;
  object-position: left;
}

.bg-warning {
  background-color: #EFE0A3 !important;
  background-color: var(--yellow) !important;
}

.bg-orange {
  background-color: #fbd5b7 !important;
  background-color: var(--orange) !important;
}

.bg-verde {
  background-color: #c7e1c5;
  background-color: var(--green);
}

.bg-blue {
  background-color: #becae1;
  background-color: var(--blue);
}

.alert {
  z-index: 1000;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.text-latina {
  color: #EDC0D3;
  color: var(--primary);
}

.panel-mobile-menu {
  display: none;
}

.form-control {
  background-color: #2c2c2c;
  background-color: var(--gray);
  color: #fff;
}

.form-control:focus {
  background-color: #2c2c2c;
  background-color: var(--gray);
  color: #fff;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.vh-100 {
  height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  border: 2px solid #EDC0D3;
  border: 2px solid var(--primary);
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.text-large {
  font-size: 1em;
}

.schedule-class {
  border-radius: 10px;
  overflow: hidden;
}

.class-container {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-col {
  width: calc(100% / 7);
}

.schedule-container {
  width: 170%;
}

.card {
  color: white;
  padding: 24px;
  border: 0.5px solid rgba(65, 65, 65, 0.1);
  border-radius: 10px;
  transition: all 0.25s;
  background-color: #2c2c2c;
  background-color: var(--gray);
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.payment-method {
  max-width: 75px;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.btn-link.text-primary:hover {
  color: white;
}

.btn-gray {
  background-color: #2c2c2c;
  background-color: var(--gray);
  color: #fff;
  border-color: #fff;
}

.btn-outline-primary {
  border-color: #EDC0D3;
  border-color: var(--primary);
  color: #EDC0D3;
  color: var(--primary);
}

.btn-outline-primary:hover {
  background-color: #EDC0D3;
  background-color: var(--primary);
  border: #EDC0D3;
  border: var(--primary);
  color: #000;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.head-coach-ribbon {
  position: absolute;
  font-weight: 800;
  color: #000;
  left: 0px;
  top: 20px;
  width: 30px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.bg-primary {
  background-color: #EDC0D3 !important;
  background-color: var(--primary) !important;
}

.text-primary {
  color: #EDC0D3 !important;
  color: var(--primary) !important;
}

.slideshow-logo {
  max-width: 450px;
}

.slideshow-img {
  height: 100vh;
  object-fit: cover;
}

.slideshow-text {
  position: absolute;
  top: 25%;
  padding: 16px;
  z-index: 10;
  max-width: 600px;
}

.carousel-overlay {
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 1) 40%,
      rgba(255, 255, 255, 0) 75%);
  opacity: 0.4;
  height: 100vh;
  position: absolute;
  display: block;
  top: 0;
  width: 75%;
}

.btn-login i {
  position: absolute;
  z-index: 1;
  left: calc(50% - 36px);
  top: calc(50% - 32px);
}

.btn-login .fa-heart {
  font-size: 72px;
}

.btn-login span {
  z-index: 10;
}

.btn-pink, .btn-pink:disabled {
  background-color: #EDC0D3;
  background-color: var(--pink);
}

.btn-primary, .btn-primary:disabled {
  background-color: #EDC0D3;
  background-color: var(--primary);
  border: 1px solid #000;
  color: #000;
}

.btn-primary:focus {
  background-color: #EDC0D3;
  background-color: var(--primary);
  box-shadow: none;
  box-shadow: none;
}

.btn-primary:hover {
  background-color: #EDC0D3;
  background-color: var(--primary);
}

.btn-primary:active {
  background-color: #EDC0D3;
  background-color: var(--primary);
  box-shadow: none;
}

.btn-primary:active {
  background-color: #EDC0D3;
  background-color: var(--primary);
  box-shadow: none;
}

.about-latina {
  min-height: 500px;
  padding: 32px;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1.4em;
}

html {
  width: 100%;
  max-width: 100%;
}

.text-english {
  font-family: "English", sans-serif !important;
  text-transform: none;
  font-size: 54px;
}

.menu-category {
  font-size: 1.2rem;
  width: 100%;
}

.menu-category:hover {
  background-color: #EDC0D3;
  background-color: var(--primary);
}

.menu-category:active {
  box-shadow: none;
  background-color: #EDC0D3;
  background-color: var(--primary);
}

.menu-category:focus {
  box-shadow: none;
  background-color: #EDC0D3;
  background-color: var(--primary);
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.menu-category .my-2 {
  background-color: #EDC0D3;
  background-color: var(--primary);
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 200 !important;
}

.nav-item {
  height: 60px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  font-weight: 800;
}

.navbar-cta {
  margin-top: 10px;
  transition: all 0.25s;
}

.navbar-cta:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.nav-item:hover {
  background-color: #EDC0D3;
  background-color: var(--primary);
  color: #000;
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  font-size: 28px;
  text-transform: uppercase;
  font-family: "Bebas Neue",sans-serif !important;
  display: inline-block;
  margin-top: 4px;
  color: inherit;
}

.nav-link:hover {
  color: #000;
}

.bg-dark {
  background-color: #000 !important;
}

.navbar {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1;
}

.top-banner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 30px;
  text-align: center;
  font-weight: 800;
  padding: 2px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas Neue", sans-serif !important;
  text-transform: uppercase;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Bebas Neue", sans-serif !important;
}

h1,h2,h3, h4, h5, h6 {
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 800;
  font-size: 2em;
  text-transform: uppercase;
}

p,
div,
a,
input,
body {
  font-family: "Archivo", sans-serif !important;
}

.btn {
  text-transform: uppercase;
  font-weight: 800;
}

.logo-landing {
  max-width: 300px;
}

.text-funny {
  font-size: 4rem;
}

.text-header {
  font-family: "Bebas Neue", sans-serif !important;
  letter-spacing: 0.5px;
  font-size: 20px;
  text-transform: uppercase;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 160px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  padding-top: 32px;
  min-height: 100vh;
}

.dropdown-item {
  color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #EDC0D3;
  background-color: var(--primary);
  color: #fff;
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding-bottom: 2rem;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.profile-image {
  max-width: 300px;
}

.video-background {
  background-image: url("https://latinatribe.s3.us-west-1.amazonaws.com/fondo-video-latina.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh
}

.thumbnail {
  max-width: 16px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.video-thumbnail {
  height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
}

.card-video-title {
  font-weight: 800;
  overflow: hidden;
  height: 40px;
}

.card-video-description {
  height: 25px;
  overflow: hidden;
}

.card-video {
  border-radius: 10px;
  height: 350px;
  margin-bottom: 32px;
  padding-bottom: 16px;
  color: #fff;
}

.card-video:hover {
  border: 2px solid #EDC0D3 !important;
  border: 2px solid var(--primary) !important;
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.banner-bg {
  padding-top: 150px;
  padding-left: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
}

.filter-card {
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-anton {
  font-family: "Bebas Neue", sans-serif !important;
}

.text-right {
  text-align: right;
}

.latest-card {
  height: 60vh;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.latest-card-overlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.8;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  width: 75%;
}

.latest-card-content {
  position: absolute;
  width: 40%;
  padding: 16px;
  top: 25%;
}
.latest-card-content h2 {
  font-size: 3em;
}

.user-name {
  font-size: 20px;
}

.user {
  background-color: #EDC0D3;
  background-color: var(--primary);
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}

.name-row:focus {
  background-color: var(--light);
}

.home-video {
  height: 90vh;
  object-fit: cover;
}

#video-row {
  margin-top: 60px !important;
}

.logo-navbar {
  max-width: 45px;
}

.vh-100 {
  height: 100vh;
}

.text-banner {
  -webkit-animation: animate 60s linear infinite;
          animation: animate 60s linear infinite;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

#main-landing {
  overflow-x: hidden;
  max-width: 100vw;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-scale {
  -webkit-transform: none !important;
          transform: none !important;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.user-menu {
  margin-left: -50%;
  background-color: #2c2c2c;
  background-color: var(--gray);
  color: #fff;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
  text-transform: uppercase;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.nav-item .mobile:focus {
  background-color: #EDC0D3;
  background-color: var(--primary);
}

.modal-content {
  background-color: #2c2c2c;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  background-color: #2c2c2c;
  background-color: var(--gray);
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.bg-gray {
  background-color: #2c2c2c;
  background-color: var(--gray);
}

.h-auto {
  height: auto;
}

.banner {
  background-image: url("https://latinatribe.s3.us-west-1.amazonaws.com/barra.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.package-description {
  max-height: 100px;
  overflow: hidden;
}

.package-card {
  height: 400px;
}

.package-card h2, .package-card p {
  color: white;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: 500px;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.coach-home-img {
  height: 400px;
  object-fit: cover;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
}
.landing-class-type-container:hover .landing-class-type {
  -webkit-filter: brightness(20%);
          filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.show-mobile {
  display: none;
}

.banner-inner {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}

.class-package-thumbnail {
  max-width: 400px;
}

td {
  min-width: 150px;
}

#scroll-text {
  /* animation properties */
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-animation: text-scroll 15s linear infinite;
  animation: text-scroll 15s linear infinite;
}

/* for Firefox */

/* for Chrome */
@-webkit-keyframes text-scroll {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes text-scroll {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 1000px) {
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
}

@media (max-width: 767px) {
  .mobile-left {
    text-align: left !important;
  }
  .app-link-overlay {
    height: 25vh;
  }
  .home-img {
    height: 50%;
  }
  .schedule-mobile-container {
    overflow-x: scroll;
  }
  .schedule-mobile-wrapper {
    width: 350%;
  }
  .show-mobile {
    display: block;
  }
  .schedule-col {
    width: calc(100% / 7);
    margin-top: 32px;
  }
  .panel-mobile-menu {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 0px;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  .slideshow-logo {
    max-width: 250px;
  }
  .latest-card-content {
    width: 100%;
  }
  .latest-card-overlay {
     width: 100%; 
  }
}

